@font-face {
  font-family: "ff-providence-sans-web-pro";
  src: url(./providence-sans.otf) format('opentype');
  font-weight: 700;
  font-style: normal;
}

html, body {
  box-sizing: content-box;
  overflow-x: hidden;
  background: #F5EFDF;
}

body {
  margin: 0;
  height: 100%;
  font-family: 'poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4 {
  margin: 0;
}

.icon-drop-shadow {
  filter: drop-shadow(0 0 .3rem rgb(0, 0, 0, 1));
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

header {
  background: #FCF8F2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 99;
  padding: 3vw 3vw 3vw 2em;
}

.header-shadow {
  /* box-shadow: 0 0 0.5em 0 #231F20; */
  border-bottom: 2px solid #231F20;
}

.logo-container {
  width: 8vw;
  height: 8vw;
  display: flex;
}

.app_title {
  font-family: "ff-providence-sans-web-pro", sans-serif;
  font-size: 2.75vw;
  font-weight: 800;
  line-height: 2em;
  color: #231F20;
  margin: 0;
  text-decoration: none;
  margin: .5em 0 0 0;
}

.hamburger_wrapper {
  width: 9vw;
  height: 9vw;
  margin: 0 3vw 0 0; 
  cursor: pointer;
}

.hamburger-line1,
.hamburger-line2 {
  background: #231F20;
  height: 8%;
  margin: 40% 0 0 auto;
  width: 100%;
  border-radius: .5vw;
  transition: all .3s ease;
}

.hamburger-line2 {
  display: flex;
}

.pan-handle {
  width: 50%;
  background: rgba(0, 0, 0, 0);
  height: 100%;
  border-radius: .5vw;
  margin: auto 0 0 0;
  transform: translate(-15%, 50%);
}

/* .show-handle-animation {
  animation: show-handle 1s ease;
}

@keyframes show-handle {
  0% {
    background: rgba(0, 0, 0, 0)
  }
  25% {
    background: rgba(0, 0, 0, 1)
  }
  50% {
    background: rgba(0, 0, 0, 1)
  }
  100% {
    background: rgba(0, 0, 0, 0)
  }
} */

.hamburger-line1 {
  width: 60%;
}

.hamburger-line2 {
  margin: 1.5vw 0 0 0;
}

.rotate-hamburger-line1 {
  animation: line1-rotate .65s linear both;
}

.rotate-hamburger-line2 {
  animation: line2-rotate .65s linear both;
}

.unrotate-hamburger-line1 {
  animation: line1-unrotate .35s ease both;
}

.unrotate-hamburger-line2 {
  animation: line2-unrotate .35s ease both;
}

@keyframes line1-rotate {
  0% {
    transform: translate(0, 0) rotate(0);
    width: 60%;
  }
  5% {
    transform: translate(.01vw, 0) rotate(0);
    width: 60%;
  }
  10% {
    transform: translate(-.01vw, .5vw) rotate(5deg);
    width: 60%;
  }
  15% {
    transform: translate(-.02vw, 2vw) rotate(10deg);
    width: 60%;
  }
  25% {
    transform: translate(0, 5vw) rotate(20deg);
    width: 60%;
  }
  35% {
    transform: translate(0, 4vw) rotate(20deg);
    width: 60%;
  }
  45% {
    transform: translate(0, -2.5vw) rotate(-20deg);
    width: 60%;
  }
  80% {
    transform: translate(-25%, -6vw) rotate(-300deg);
    width: 70%;
  }
  100% {
    transform: translate(0%, .25vw) rotate(-495deg);
    width: 100%;
  } 
}

@keyframes line2-rotate {
  0% {
    transform: translate(0, 0) rotate(0);
  }
  5% {
    transform: translate(.01vw, 0) rotate(0);
  }
  10% {
    transform: translate(-.01vw, 0) rotate(5deg);
  }
  15% {
    transform: translate(-.02vw, 1.5vw) rotate(10deg);
  }
  25% {
    transform: translate(0, 4vw) rotate(20deg);
  }
  35% {
    transform: translate(0, 3vw) rotate(20deg);
  }
  45% {
    transform: translate(0, -1.5vw) rotate(-20deg);
  }
  55% {
    transform: translate(0, -2.5vw) rotate(-45deg);
  }
  100% {
    transform: translate(0, -2vw) rotate(-45deg);
  }
}

@keyframes line1-unrotate {
  0% {
    transform: translate(0%, .55vw) rotate(-135deg);
    width: 100%;
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
    width: 60%;
  }
}

@keyframes line2-unrotate {
  0% {
    transform: translate(0, -2vw) rotate(-45deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

.nav-menu-container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0);
  z-index: 2;
  transition: background-color .35s ease;
}

.nav-menu-container.show {
  background-color: rgba(0, 0, 0, .68);
}

#nav-menu {
  position: absolute;
  top: 0;
  right: 0;
  background: #F5EFDF;
  width: 70%;
  height: 100%;
  margin: 0 0 0 auto;
  padding: 23vw 0 0 0;
  transform: translateX(100%);
  transition: transform .5s ease;
  opacity: 1;
  pointer-events: all;
}

#nav-menu.show {
  transform: translateX(0);
  box-shadow: 0 0 1em;
}

#nav-menu * {
  width: 75%;
  margin: 1rem 0 1rem auto;
  text-decoration: none;
  font-size: 3vh;
  font-weight: 500;
  color: #231F20;
}

#nav-search-bar,
.recipe-search-bar {
  width: 55%;
  color: gray;
  margin: 0 auto 5vw auto;
  padding: 1vw 5vw;
  font-size: 2vh;
  font-weight: 400;
  border: 2px solid black;
  border-radius: 2em;
}

.recipe-search-bar {
  width: 40%;
  font-size: 1em;
  padding: .2em 5vw;
  background: #FCF8F2;
}

.footer-wrapper {
  bottom: 0;
  background: #231F20;
  padding: 3em 0;
}

.footer-logo-container {
  width: 100%;
  display: flex;
}

.footer-logo {
  width: 15%;
  margin: 0 auto;
}

.footer-content {
  color: #FCF8F2;
  text-align: center;
}

main {
  height: 100%;
  min-height: 100vh;
  padding: 20vw 0 3em 0;
}

.home-animation {
  height: 55vh;
}

.home-btn-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.home-btn-container a {
  text-decoration: none;
}

.recipes-button-container {
  width: 100%;
  display: flex;
}

.recipes-button-container:focus {
  text-decoration: none;
}

.recipes-button,
.upload-btn {
  font-family: 'poppins', sans-serif;
  font-weight: 300;
  margin: 0 auto;
  border: none;
  cursor: pointer;
  text-decoration: none;
  color: white;
}

.recipes-button {
  font-size: 1em;
  background: #016435;
  color: #F5EFDF;
  padding: .5em 2em;
  border-radius: 1.5em;
}

.upload-button-container {
  margin: .5em auto;
}

.upload-button-container:focus {
  text-decoration: none;
}

.upload-btn {
  text-align: center;
  margin: .25em 0;
  border: none;
  background: none;
  color: black;
}

.home-screen-recipes {
  background: #FCF8F2;
  padding: 1em 0 1.5em 0;
}

.no-ads-text-conainer {
  font-family: "ff-providence-sans-web-pro", sans-serif;
  text-align: center;
  font-size: 1.5em;
  margin: 25vh 0;
  color: #B2B2B2;
}

.dark-grey {
  color: #231F20;
}

.cardSectionContainer {
  margin: .5em 0 0 0;
  padding: .5em 0 1em 0;
  background: #FCF8F2;
}

.cardSectionHeading {
  margin: 2em 2em .5em;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.homescreen-section-title {
  font-weight: 500;
  text-transform: capitalize;
  white-space: nowrap;
}

.scrollArrowContainer {
  width: 100%;
  height: 100%;
  margin: 0 0 0 1em;
  display: flex;
}

.scrollProgressContainer{
  height: 15px;
}

.scrollArrowTail {
  background: black;
  width: 100%;
  height: 3px;
  margin: auto auto auto 0;
  grid-column: 1;
  grid-row: 1;
}

/* .arrowHead {
  background: red;
  width: 5px;
  height: 10px;
  border-radius: 50%;
  margin: 0 0 0 auto;
  display: inline-block;
  transform: translateY(-100%);
} */

.arrowHead {
  height: 20px;
  margin: 0;
  grid-column: 1;
  grid-row: 1;
  margin: 0 0 0 auto;
}


.cardsScrollContainer {
  overflow-x: auto;
  /* scroll-behavior: smooth; */
}

.cardsScrollContainer::-webkit-scrollbar {
  display: none;
}

.cardsContainer {
  display: inline-flex;
  width: auto;
  margin: 0 2em 0 0;
  padding: 3vw 0 4vw 0;
}

.card {
  display: flex;
  position: relative;
  height: 33vw;
  width: 34vw;
  /* border: 2px solid black; */
  /* box-shadow: 0px 3px 7px rgb(0 0 0 / 0.5); */
  border-radius: 12%;
  margin: 0 0 0 2em;
  cursor: pointer;
  background-size: cover;
  background-position: center;
}

.card-link-container {
  height: 100%;
  width: 100%;
  padding: 0 0 1.2vw 1.2vw;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  text-decoration: none;
}

.recipe-card-name-link {
  color: black;
  text-decoration: none;
}

.recipe-name {
  margin: .75em 0 0 2.25em;
  font-size: .9rem;
}

.card-text-container {
  margin: auto 0 1.2vw 0;
  width: 80%;
}

.recipe-card-name {
  width: 100%;
  color: white;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1em;
  grid-column: 1;
  grid-row: 3;
  font-size: 3.25vw;
}

.recipe-subheader {
  color: white;
  font-weight: 500;
  line-height: 1em;
  font-size: 1.75vw;
}

.star-icon-container {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0 0 0 auto;
  padding: 1.5vw 1vw 1.5vw 1.5vw;
  grid-column: 2;
  grid-row: 1;
  width: 4vw;
}

.star-icon {
  color: #FFEE51;
  width: 3.5vw;
  justify-content: center;
}

.rotate {
  /* transform: rotate(-360deg); */
  animation: starRotateAnimation 1s ease;
}

.unrotate {
  animation: starUnrotateAnimation .75s ease;
}

.ringing {
  animation: bellRingingAnimation 1s ease;
}

@keyframes bellRingingAnimation {
  10% {
    transform: rotate(-7deg) scale(1);
  }
  20% {
    transform: rotate(7deg) scale(1.25);
  }
  30% {
    transform: rotate(-7deg) scale(1.25);
  }
  40% {
    transform: rotate(7deg) scale(1.25);
  }
  50% {
    transform: rotate(-7deg) scale(1.25);
  }
  60% {
    transform: rotate(7deg) scale(1.25);
  }
  70% {
    transform: rotate(0deg) scale(1.10);
  }
  80% {
    transform: rotate(0deg) scale(1);
  }

}

@keyframes starRotateAnimation {
  0% {
    transform: rotate(0deg) scale(1);
  }
  70% {
    transform: rotate(-360deg) scale(1.4);
  }
  100% {
    transform: rotate(-360deg) scale(1);
  }
}

@keyframes starUnrotateAnimation {
  0% {
    transform: rotate(-360deg) scale(1);
  }
  70% {
    transform: rotate(0deg) scale(.6);
  }
  100% {
    transform: rotate(0deg) scale(1);
  }
}

.bell-icon-above-text{
  margin: auto 0 .7vw .2vw;
  width: 1.65vw;
}

.bell-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  margin: auto 1.75vw 2vw auto;
  width: 3vw;
}

.end-line {
  content: '';
  width: 3px;
  background: black;
  margin: 0 0 0 2em;
}

.nothing-here-text {
  margin: auto 1em;
  font-weight: 600;
}

.add-recipes-section {
  width: 80%;
  margin: 15vw auto 10vw auto;
}

.plus-icon {
  width: 12vw;
  height: 12vw;
  margin: 3vw auto;
  border-radius: 50%;
  background: #64EB79;
  border: 3px solid black;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plus-icon img {
  height: 50%;
  width: 50%;
}

.plus-icon:hover {
  background: #64EB79;
}

.add-recipes-section > * {
  text-align: center;
}

/* add recipe page */

.recipe-page-icon-container {
  display: flex;
  margin: 0 2rem 1rem 2rem;
  justify-content: space-between;
  align-items: center;
}

.recipe-page-icon-buttons {
  display: flex;
  align-items: center;
}

.recipe-page-star {
  width: 5vw;
}

.recipe-page-bell {
  margin: .01vw 2vw 0 0;
  width: 5vw;
}

.delete-icon-container {
  width: 1.5em;
  margin: 0 .5em 0 0;
  display: flex;
  align-items: flex-end;
  cursor: pointer;
}

.delete-icon-container img {
  margin: auto 0 0 0;
}

.delete-modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 3;
  background: rgba(0, 0, 0, .80);
  display: grid;
}

.delete-modal {
  background: white;
  margin: auto;
  width: 90%;
  height: 50%;
  opacity: 100%;
  border-radius: 1rem;
  border: 3px solid black;
}

.close-btn-container {
  width: 1.25rem;
  height: 1.25rem;
  margin: 1.5rem 1.5rem 0 auto;
  cursor: pointer;
}

.deletion-form {
  display: grid;
  width: 90%;
  margin: 2rem auto;
}

.deletion-form input {
  margin: 1rem;
}

.back-arrow-container,
.recipe-page-back-arrow-container {
  width: 2.5em;
  height: 2em;
  margin: 0 0 0 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.back-arrowhead {
  height: 80%;
  transform: rotate(180deg);
  position: absolute;
}

.back-arrow {
  height: 10%;
  border-radius: 8px;
  width: 100%;
  background-color: black;
  position: absolute;
}

#recipe-page-title,
#recipe-page-subtitle {
  text-align: center;
  font-size: 1.5rem;
  line-height: 2.25rem;
  font-weight: 900;
  margin: 0 5vw;
  text-transform: capitalize;
}

#recipe-page-subtitle {
  font-size: 1em;
  line-height: 1em;
  font-weight: 600;
}

.info-overview {
  display: flex;
  flex-direction: row;
  width: 80%;
  justify-content: space-between;
  align-items: center;
  font-size: .75em;
  margin: 0 auto;
  background: #FCF8F2;
  padding: 1em .5em;
}

.dificulty-overview {
  flex: 1;
  text-align: center;
}

.time-overview-container {
  flex: 1.5;
  position: relative;
}

.time-overview {
  display: flex;
  padding: 1.5vw;
  align-items: center;
  justify-content: center;
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.time-disclaimer {
  position: absolute;
  color: green;
  width: 100%;
  margin: auto;
  font-size: .75em;
  text-align: center;
}

.time-overview-img {
  margin: 0 2vw 0 0;
}

.overview-timer-icon {
  width: 5vw;
  height: auto;
}

.time-overview-text {
  font-weight: 700;
  line-height: 1.2em;
}

.time-overview-subtext {
  font-size: .75em;
  color: #706A6A;
}

.serving-overview{
  flex: 1;
  text-align: center;
}

.add-recipe-form,
.edit-recipe-form,
.temp-img-form {
  display: grid;
  width: 100%;
  margin: 1rem auto;
}

.recipe-form-title,
.recipe-title,
.edit-form-title {
  font-size: 7vw;
  font-weight: 600;
  text-align: center;
}

.edit-form-title {
  margin: 2rem 0 0 0;
  line-height: 7vw;
}

.edit-form-subtitle {
  margin: 0 0 3rem 0;
  font-size: 3vw;
  font-weight: 400;
  text-align: center;
}

/* .recipe-preview-container {
  background: #F5F5F5;
  box-shadow: -5px 6px 4px rgb(0 0 0 / 0.25);
  width: 88vw;
  height: 50vw;
  border: 4px solid black;
  border-radius: 1.25rem;
  margin: 2rem auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
} */

.image-upload-btn,
.recipe-page-hero {
  box-shadow: -5px 6px 4px rgb(0 0 0 / 0.25);
  width: 88vw;
  height: 50vw;
  border: 4px solid black;
  border-radius: 1.25rem;
  margin: 1rem auto;
  display: grid;
  padding: 0;
  background-position: center;
  background-size: cover;
}

.image-upload-btn {
  cursor: pointer;
}

.small-img-btn {
  display: block;
  font-size: 1rem;
  font-weight: 600;
  padding: .75rem 3rem;
  border-radius: 6rem;
  margin: 0 auto;
  cursor: pointer;
  border: 3px solid black;
  text-decoration: none;
}

.small-img-btn:hover {
  background: #C0C0C0;
}

.recipe-page-hero {
  align-items: flex-end;
 }

#preview-gradient {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  background-image: linear-gradient(12deg, rgba(0, 0, 0, 0.95), rgba(0, 0, 0, 0)40% );
  grid-column: 1;
  grid-row: 1;
}

#upload-image-prompt {
  font-size: 1.5rem;
  font-weight: 500;
  color: #C0C0C0;
  grid-column: 1;
  grid-row: 1;
  margin: auto 0;
}

#edit-image-prompt {
  height: 100%;
}

.preview-text-container {
  margin: auto 0 .25rem 0;
  width: 85%;
  grid-column: 1;
  grid-row: 1;
}

#imageInput {
  display: none;
}

#recipe-title-preview,
#recipe-title-overlay {
  text-align: left;
  font-size: 2rem;
  font-weight: 800;
  margin: .5rem .5rem 0 1.5rem;
  line-height: 2rem;
  text-transform: uppercase;
}

#recipe-title-overlay {
  color: white;
}

#recipe-subtitle-preview,
#recipe-subtitle-overlay {
  text-align: left;
  margin: 0 .5rem .5rem 1.5rem;
  font-weight: 600;
}

#recipe-subtitle-overlay {
  font-size: 1rem;
  line-height: 1rem;
  color: white;
}

.visibility-label {
  font-weight: 500;
  font-size: 1rem;
}

.radio-btn-container {
  background: #F5F5F5;
  display: flex;
  justify-content: left;
  align-items: flex-start;
}

.add-recipe-form .radio-btn,
.edit-recipe-form .radio-btn {
  margin: .5em 0 .25em 0;
}

.visibility-btn-container {
  display: flex;
  margin: 0 0 2em 0;
  width: 100%;
  justify-content: space-between;
  text-align: center;
  margin: 0 0 3em 0;
}

.visibility-btn-container * {
  margin: .25em;
}

.visibility-sub-text {
  font-size: .75em;
  color: #7E7E7E;
  padding: 0 .5em;
}

.title-section,
.info-section,
.instructions-section,
.ingredients-section,
.notes-section,
.extra-info-section,
.tags-section,
.stats-section,
.equipment-section,
.original-link-section {
  margin: 0 0 3em 0;
  display: grid;
  grid-template-columns: auto 1fr 1fr;
  grid-template-rows: auto auto auto;
  gap: 5px;
  font-size: .9em;
}

.recipe-page .ingredients-section {
  margin: 2em 0 0 0;
}

.ingredients-section-title-container {
  grid-column: 1 / span 3;
  display: flex;
  position: relative;
  align-items: flex-end;
}

.dynamic-servings-current-selection {
  flex: 1;
  white-space: nowrap;
  position: relative;
  display: flex;
  margin: 0 5vw 0 0;
  color: #706A6A;
  cursor: pointer;
}

.current-serving-display {
  padding: 0 1em;
  line-height: .9em;
}

.gray-down-arrow {
  width: .5em;
  height: .5em;
  margin: 0 auto;
  transform: rotate(45deg);
  border-right: 1px solid #706A6A;
  border-bottom: 1px solid #706A6A;
}

.dynamic-servings-selection-container {
  background: white;
  position: absolute;
  top: 100%;
  width: 100%;
  margin: .5em 0 0 0;
  border: 1px solid black;
  text-align: center;
  padding: .5em 0;
}

.servings-modal-container {
  background: rgb(0 0 0 / 0.80);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.custom-servings-modal {
  background: white;
  width: 50%;
  margin: auto;
  opacity: 1;
  border-radius: 1rem;
  border: 3px solid black;
  font-weight: 500;
}

.custom-serving-label {
  width: 100%;
  text-align: center;
  display: block;
  margin: 8% 0 0 0;
}

.custom-serving-input-container {
  display: flex;
  justify-content: center;
}

.plus-minus-btn {
  background: black;
  color: white;
  margin: auto .75em;
  padding: .25em 1em;
  border-radius: .5em;
}

#custom-serving {
  margin: 3% 0;
  padding: .5em;
  display: block;
  text-align: center;
  border: 2px solid black;
  font-weight: 600;
}

.ok-button-container {
  display: flex;
  justify-content: center;
  margin: 5% 0;
}

.ok-button {
  display: inline-block;
  background: #C0C0C0;
  margin: 5% 0;
  color: black;
  cursor: pointer;
  padding: .15rem 1rem;
  text-align: center;
  font-weight: 500;
  border: 2px solid black;
  border-radius: 1.5rem;
}

.tags-section {
  margin: 0 0 0 0;
}

.section-title {
  grid-column: 1 / span 3;
  width: 100%;
  font-size: 1.25rem;
  font-weight: 800;
}

.section-arrow-container {
  grid-column: 1;
  grid-row: 2 / span 2;
  border-left: 3px solid black;
  display: flex;
  justify-content: left;
  align-items: flex-end;
  position: relative;
  margin: 0 .9rem 0 0;
}

.section-arrowhead {
  transform: rotate(90deg) translateX(.3rem) translateY(.425rem);
  margin: 0;
  position: absolute;
  bottom: 0;
  left: 0;
}

.section-input-container,
.section-content-container {
  grid-column: 2 / span 2;
  grid-row: 2 / span 2;
  margin: .5rem 0 .75rem 0;
}

.add-recipe-form label,
.edit-recipe-form label,
.add-recipe-form select,
.edit-recipe-form select {
  font-size: 1rem;
  font-weight: 500;
  display: block;
}

.add-recipe-form input,
.add-recipe-form textarea,
.edit-recipe-form input,
.edit-recipe-form textarea,
.add-recipe-form select,
.edit-recipe-form select {
  font-family: 'poppins', sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
  background: #F5F5F5;
  padding: .5rem .75rem;
  width: 92%;
  margin: .2rem auto 1rem auto;
  border: 3px solid black;
  vertical-align: bottom;
}

.add-recipe-form select,
.edit-recipe-form select {
  margin: .2rem 0 1rem 0;
  width: 50%;
}

.has-placeholder::placeholder {
  color: #C0C0C0;
}

#current-ingredient-preview {
  margin: .5rem 0 1.25rem 0;
}

#ingredient-measurement-preview,
.ingredient-measurement-preview,
.ingredient-quantity-preview {
  font-weight: 700;
  margin: 0 .4em 0 0;
}

#ingredient-name-preview,
.ingredient-name-preview {
  margin: 0 .25em 0 0;
  font-weight: 500;
}

#ingredient-extra-detail-preview, 
.ingredient-extra-detail-preview {
  margin: 0 .25em 0 0;
  color: #7E7E7E;
}

.ingredient-section-preview,
.instruction-section-header {
  display: inline-block;
  margin: 2em 0 0 0;
  font-size: 1.2em;
  color: #7E7E7E;
}

.equipmentPreview {
  margin: 0 0 1em 0;
}

.section-input-container > .instruction-section-header:first-child {
  margin: 0 0 1em 0;
}

#ingredient-quantity-preview,
#ingredient-measurement-preview,
#ingredient-name-preview,
#ingredient-extra-detail-preview,
#ingredient-section-preview {
  font-size: 1.25rem;
  color: green;
}

#ingredient-quantity-preview {
  font-weight: 600;
  margin: 0 .25em 0 0;
}

.ingredient-txt-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.ingredient-preview-element {
  cursor: pointer;
  display: flex;
  margin: 0 0 0 .25em;
}

.ingredient-quantity-preview {
  margin: 0 .4em 0 0;
  font-weight: 900;
  display: flex;
}

.ingredient-quantity-preview > .fraction-container {

}

.bullet-point {
  display: inline-block;
  background: #D1D1D1;
  height: .5em;
  width: .5em;
  margin: auto 1em auto 0;
  border-radius: 50%;
}

.ingredients-button-container {
  display: flex;
  align-items: center;
}

.add-ingredients-header {
  cursor: pointer;
  font-weight: 600;
  margin: 0 1em;
}

.ingredients-content-container > .ingredient-preview-element:first-child > .ingredient-txt-wrapper > .ingredient-section-preview {
  margin: 0 0 0 0;
}

.fraction-container {
  
  display: inline-flex;
  
}

.numerator,
.fraction-slash,
.denominator {
  font-size: .7em;
}

.numerator {
  padding: 0 0 .6em 0;
}

.fraction-slash {
  rotate: 15deg;
  padding: .35em 0 0 0;
}

.denominator {
  padding: .6em 0 0 0;
}

.number-pre-fraction {
  margin: 0 .3em 0 0;
}

.updated-quantity-animation {
  animation: transitionColor 3s ease;
}

@keyframes transitionColor {
  .01% {
    color: #761ABF;
  }
  15% {
    color: #761ABF;
  }
  100% {
    color: #000000;
  }
}

.invalid-quantity {
  font-size: .75em;
  color: red;
}

.delete-btn,
.edit-btn {
  margin: 0 0 0 .5rem;
  cursor: pointer;
  color: red;
  font-weight: 400;
}

.delete-btn:hover,
.edit-btn:hover {
  font-weight: 600;
}

.edit-btn {
  color: #3743b8;
}

.reorder-btn-container {
  display: flex;
  align-items: center;
}

.reorder-btn {
  display: flex;
  width: 1.25em;
  height: 1.25em;
  border: 1px solid #7E7E7E;
  border-radius: 50%;
  margin: auto .25em;
  justify-content: center;
  align-items: center;
}

.reorder-arrow {
  width: 40%;
  height: 40%;
  border-right: 1px solid #7E7E7E;
  border-bottom: 1px solid #7E7E7E;
  transform: rotate(45deg);
  margin: 0 0 15% 0;
}

.duplicate-alert {
  color: red;
  font-weight: 700;
}

#instructions {
  font-size: 1rem;
}

.instruction-item-container {
  margin: 1.5em 0 0 0;
  background: #EDE4CE;
  padding: 1em .5em;
}

.instruction-section-header + .instruction-item-container {
  margin: 0 0 0 0;
}

.instruction-text-container {
  padding: .5em .25em;
  background: #F5F5F5;
  margin: 1em 0;

}

.instruction-step-label {
  font-weight: 700;
  font-size: .9em;
  cursor: pointer;
  position: relative;
}

.controls-container-parent {
  background: orange;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  
}

.ingredient-controls-container {
  padding: 0 1em;
  display: flex;
  font-size: .8rem;
  align-items: center;
  margin: 0 0 0 auto;
}

.instruction-step {
  font-weight: 700;
  font-size: .9em;
}

.instruction-text {
  white-space: pre-line;
  font-size: 1rem;
  font-weight: 400;
}

.now-editing {
  background: #deffde;
}

.instruction-section-header {
  margin: 2em 0 .5em 0;
  cursor: pointer;
  width: 100%;
  position: relative;
}



.instruction-controls-container {
  position: absolute;
  right: 0;
  top: 0;
  padding: 0 1em;
  display: flex;
  font-size: .8rem;
}

#notes {
  font-size: 1rem;
}

.section-label-container > .controls-container {
  height: 100%;
  display: flex;
  align-items: center;
}

.section-label-container > .hide {
  display: none;
}

#cooktime-hours,
#cooktime-mins,
#preptime-hours,
#preptime-mins,
#serving-size-input,
#nutrition-score,
#cost-score,
#tastiness-score {
  display: inline-block;
  width: 2em;
  padding: .25rem;
}

.post-input-inline-text {
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  margin: 0 2rem 1.25rem .5rem;
}

#tags-container .tag {
  font-size: 1rem;
  padding: .25rem 1rem;
  border-radius: 10vw;
  cursor: pointer;
  display: inline-block;
  width: auto;
}

/* #tags-container {
  background: orange;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
} */

#tags-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: .15rem;
  margin-bottom: 0;
}

.form-tag {
  font-size: 2.75vw;
  border: 3px solid black;
  background: #F5F5F5;
  padding: .25rem 1rem;
  padding: .75vw 3vw;
  border-radius: 10vw;
  cursor: pointer;
  margin: .25rem .25rem;
  text-align: center;
}

.two-column-tag {
  grid-column: span 2;
}

#tags-container .tag:hover,
.form-tag:hover {
  background: #C0C0C0;
}

.selected,
.selected:hover {
  background: #E2FF6E;
}

.show-more-tags-container {
  display: flex;
  align-items: flex-end;
  font-size: 3.5vw;
  line-height: 3.5vw;
  color: #BDBDBD;
  margin: .5rem 0 3rem 2.5rem;
  cursor: pointer;
}

.show-more-arrow-container {
  margin: 0 0 0 1.5vw;
  width: 3.5vw;
  height: 3.5vw;
}

.show-more-arrow {
  margin: 1vw 0 0 .5vw;
  height: 70%;
  transform: rotate(90deg);
}

.rotate270 {
  transform: rotate(270deg);
}

.add-recipe-form,
.temp-img-form,
.edit-recipe-form {
  display: grid;
  width: 90%;
  margin: 2rem auto;
}

.tempDatabaseView {
  width: 90%;
  margin: 0 auto;
}

.tempImgPreview {
  width: 10vw;
}

.submit-recipe-btn,
.add-button,
.save-recipe-btn,
.delete-recipe-btn{
  padding: 1rem 0;
  width: 50%;
  margin: 1rem auto;
  text-transform: uppercase;
  font-weight: 800;
  cursor: pointer;
  background: #64EB79;
  border-radius: 10vw;
  border: 3px solid black;
}

.save-recipe-btn,
.delete-recipe-btn {
  width: 80%;
  margin: 3rem auto 0 auto;
  padding: .75rem 0
}

.delete-btn-container {
  display: grid;
  width: 90%;
  margin: 0 auto;
}

.delete-recipe-btn {
  background: rgb(250, 98, 98);
  margin: 0 auto;
}

.delete-recipe-btn:hover {
  background: red;
}

.delete-modal-text {
  width: 80%;
  font-size: 1.5rem;
  text-align: center;
  margin: 2rem auto;
}

.yes-no-btn-container {
  width: 80%;
  margin: 0 auto;
  justify-content: space-around;
  display: flex;
}

.delete-modal-btn {
  cursor: pointer;
  width: 40%;
  padding: .25rem 0;
  text-align: center;
  font-weight: 600;
  border: 3px solid black;
  border-radius: 1.5rem;
}

#delete-no {
  background: #C0C0C0;
}

.delete-modal-btn:hover {
  background: #C0C0C0;
}

#delete-no:hover {
  background: #949494;
}

#deletion-password {
  padding: .5rem;
  font-size: 1rem;
  border: 2px solid black;
}

#deletion-password::placeholder {
  color: #949494;
}

.add-button {
  display: inline-block;
  width: auto;
  padding: .25rem 1.5rem;
  margin: 0 0;
  font-weight: 600;
}

.submit-recipe-btn:hover,
.add-button:hover,
.save-recipe-btn:hover,
.plus-icon:hover {
  background: #22F043;
}

.error {
  text-align: center;
  margin: 0 0 1rem 0;
  color: red;
}

#honeyp,
#deletion-honeyp {
  display: none;
}

.success-message-container {
  height: 3em;
}

.success-el {
  text-align: center;
  color: green;
  font-size: 2em;
  font-weight: 800;
}

/* full recipe page */

.recipe-page section {
  width: 90%;
  margin: 1.5em auto 3.5em auto;
}

.recipe-page section.ingredients-section {
  margin: 3em auto 3.5em auto;
}

#back-text  {
  margin: 0 0 0 3.5em;
  font-weight: 600;
  text-decoration: none;
  color: black;
}

.show-line-breaks {
  white-space: pre-line;
}

.taglist {
  margin: 0 .25rem
}

.checkbox-container {
  display: flex;
  margin: 5em 2em 2em 2em;
}

#completed-checbox {
  width: 8vw;
  height: 8vw;
  border: 1vw black solid;
  margin: 0 1em 0 0;
  cursor: pointer;
  display: grid;
}

#checkmark {
  width: 80%;
  height: 90%;
  margin: auto;
}

.completed-text {
  font-size: 5.5vw;
  font-weight: 800;
  line-height: 6vw;
}

.edit-text-container {
  margin: 5rem 0 4rem 0;
  text-align: center;
  display: flex;
  justify-content: center;
}

.edit-recipe-link {
  text-decoration: none;
  color: black;
}

.edit-text {
  font-size: 1.25rem;
  font-weight: 700;
  padding: 0 .5em;
  border-bottom: 3px solid black;
  cursor: pointer;
}

.overall-score-container {
  
}

.score-bar-background {
  width: 98%;
  background: black;
  height: .5em;
  border: 2px solid black;
  border-radius: .5em;
}

.overall-score-bar {
  
}

.recipe-scores-container {
  display: flex;
  justify-content: space-between;
  margin: 1em 0 2em 0;
}

.score-data-container {
  margin: 0 4% 0 0;
  width: 100%;
}

.score-bar-icon-container {
  width: 100%;
  height: 3vw;
  display: flex;
  justify-content: space-between;
  margin: 0 0 .5vw 0;
  font-size: 2vw;
  line-height: 2vw;
  color: #A0A0A0;
  align-items: flex-end;
}

.timer-icon-container {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.timer-icon-container > img {
  margin: 0 .25vw 0 0;
}

.timerIcon {
  height: 70%;
}

.score-icon {
  width: 12%;
}

.score-text-container {
  text-align: center;
}

.score-label-text {
  font-size: 2.1vw;
  text-align: center;
}

.score-number-text {
  font-size: 2vw;
  font-weight: 600;
}

.bottom-back-arrow {
  margin: 0 0 2rem 2rem;
}

.back-arrow-link {
  text-decoration: none;
}

.n {
  color: red;
}

.d {
color: green;
}

.deletion-message-container {
  position: fixed;
  background: rgba(0, 0, 0, .80);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 2;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.deletion-message {
  width: 60%;
  text-align: center;
  font-size: 2em;
  font-weight: 600;
}

.loading-message {
  font-weight: 500;
  display: flex;
  justify-content: center;
  font-size: 1.5em;
  margin: 35vh 0 0 0;
} 

/* utility classes */
.white-text {
  color: white;
}

.opacity0 {
  opacity: 0;
}

.hide {
  display: none;
}

.transparent {
  opacity: 0;
}

.disable-input {
  opacity: .25;
}

.no-margin {
  margin: 0;
}

.disable-pointer-events {
  pointer-events: none;
}

.grey-text {
  color: #706A6A;
}

.weight600 {
  font-weight: 600;
}

.rotate180 {
  transform: rotate(180deg);
}

@media screen and (min-width: 850px) {
  
  header {
    padding: 1vw 1vw 1vw 2em;
  }

  .app_title {
    font-size: 1vw;
  }

  .hamburger_wrapper {
    width: 2vw;
    height: 2vw;
  }

  .hamburger-line2 {
    margin: .5vw 0 0 0;
  }

  .rotate-hamburger-line1 {
    transform: rotate(45deg)  translate(1px, .5vw);
    transition: all .3s ease;
  }
  
  .rotate-hamburger-line2 {
    width: 100%;
    transform: rotate(-45deg) translate(1px, -.5vw);
    transition: all .3s ease;
  }

  main {
    padding: 10vw 0 0 0;
  }

  .card {
    width: 20vw;
    height: 27vw;
  }

  .recipe-card-name {
    font-size: 1.75vw;
  }
  
  .recipe-subheader {
    font-size: 1vw;
  }
  
  #nav-menu {
    padding: 7vw 0 0 0;
    width: 30%;
  }

}


